import { PolygonDrawerBase } from "./PolygonDrawerBase";
export class PolygonDrawer extends PolygonDrawerBase {
    getCenter(particle, radius) {
        return {
            x: -radius / (particle.sides / 3.5),
            y: -radius / (2.66 / 3.5),
        };
    }
    getSidesData(particle, radius) {
        const sides = particle.sides;
        return {
            count: {
                denominator: 1,
                numerator: sides,
            },
            length: (radius * 2.66) / (sides / 3),
        };
    }
}
